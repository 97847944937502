$(document).ready(function () {
  SC.hideSuccessAlertMessages();
  SC.hideSuccessFlashMessages();
  SC.hideErrorAlertMessages();
  SC.hideErrorDangerMessages();
  $('input.datepicker').attr('autocomplete', 'off');
});

SC = {
  hideSuccessFlashMessages: function () {
    $('.flash_notice').fadeOut(2000, function () {
      $('.flash_notice').remove();
    });
  },
  hideSuccessAlertMessages: function () {
    $('.alert-success').fadeOut(2000, function () {
      $('.alert-success').remove();
    });
  },
  hideErrorAlertMessages: function () {
    $('.flash_alert').fadeOut(2000, function () {
      $('.flash_alert').remove();
    });
  },
  hideErrorDangerMessages: function () {
    $('.alert-danger').fadeOut(2000, function () {
      $('.alert-danger').remove();
    });
  },
};
